<template>
  <BasePopupModal :modal="modal" :overlay-color="'white'" :width="900">
    <div class="edit-question-modal">
      <div class="edit-question-modal--header font-weight-light">
        Ajout d’une question
      </div>
      <div class="edit-question-modal--content">
        <div class="edit-question-modal--content-input d-flex justify-center">
          <v-form class="edit-question-modal--content-input-form">
            <v-container>
              <!-- number -->
              <v-text-field v-model="formData.position" :label="'Position'" type="number" disabled outline> </v-text-field>
              <!-- theme  -->
              <v-select
                v-model="formData.questionThemeId"
                :items="themes"
                item-value="id"
                item-text="label"
                filled
                :label="'Thème'"
              >
              </v-select>
              <!-- type  -->
              <v-select
                v-model="formData.type"
                :items="questionType"
                item-value="value"
                item-text="text"
                filled
                :label="'Type'"
                @change="changeType()"
              >
              </v-select>
              <v-text-field
                v-if="formData.type === 0"
                v-model="formData.answerNumbers"
                :label="'Nombre de réponses:'"
                type="number"
                outline
                @change="
                  checkAnswerNumbers();
                  changeAnswers();
                "
              >
              </v-text-field>
              <!-- good answers  -->
              <v-select
                v-if="formData.type === 0"
                v-model="formData.goodAnswers"
                :items="availableGoodAnswers"
                chips
                :label="'Réponses attendues:'"
                :change="setMaxPointNumbers()"
                multiple
                filled
              ></v-select>
              <v-text-field v-if="formData.type !== 0" v-model="formData.goodAnswers" :label="'Réponses attendues:'" outline>
              </v-text-field>
              <v-select
                v-if="formData.type === 0"
                v-model="formData.badAnswers"
                :items="availableBadAnswers"
                chips
                :label="'Réponses pénalisantes:'"
                multiple
                filled
              ></v-select>
              <!-- maxPoint  -->
              <v-text-field v-model="formData.maxPoint" :label="'Nombre de points maximum:'" type="number" :disabled="formData.type === 0" outline>
              </v-text-field>
            </v-container>
          </v-form>
        </div>
      </div>
      <div class="edit-question-modal--buttons d-flex justify-end">
        <v-btn class="edit-question-modal--buttons-cancel" outlined depressed @click="close">
          Annuler
        </v-btn>
        <v-btn class="edit-question-modal--buttons-save" color="#38BB00" dark depressed :disabled="!isValidate" @click="submit">
          Valider
        </v-btn>
      </div>
    </div>
  </BasePopupModal>
</template>

<script>
import { cloneDeep, xor } from 'lodash';

import BasePopupModal from '@/components/modals/base/BasePopupModal';

export default {
  name: 'EditQuestionModal',
  components: {
    BasePopupModal
  },
  props: {
    modal: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: {
        position: 0,
        questionThemeId: '',
        type: 0,
        goodAnswers: [],
        badAnswers: [],
        answerNumbers: 16,
        maxPoint: 0
      },
      questionType: [
        {
          text: 'Choix multiple',
          value: 0
        },
        {
          text: 'Libre',
          value: 1
        },
        {
          text: 'Papier',
          value: 2
        }
      ]
    };
  },
  computed: {
    oldValues() {
      return this.modal.payload.oldValues;
    },
    modalType() {
      const modal = ['edit', 'new'];
      return modal[this.type];
    },
    themes() {
      return this.modal.payload.themes;
    },
    answersList() {
      const alpha = Array.from(Array(Number(this.formData.answerNumbers))).map((e, i) => i + 65);
      return alpha.map(x => String.fromCharCode(x).toLowerCase());
    },
    availableGoodAnswers() {
      return xor(this.answersList, this.formData.badAnswers);
    },
    availableBadAnswers() {
      return xor(this.answersList, this.formData.goodAnswers);
    },
    isValidate() {
      if (this.formData.type === 0 && !this.formData.goodAnswers.length) {
        return false;
      }

      if (this.formData.type !== 0 && !this.formData.goodAnswers) {
        return false;
      }

      if (this.formData.questionThemeId === '') {
        return false;
      }

      if (this.formData.maxPoint === 0) {
        return false;
      }

      return true;
    },
    answerNumbers() {
      return this.formData.answerNumbers;
    }
  },
  created() {
    if (this.oldValues) {
      this.formData = cloneDeep(this.oldValues);
      if (this.oldValues.type === 0) {
        this.formData.goodAnswers = this.formData.goodAnswers.split(',');
        this.formData.badAnswers = this.formData.badAnswers.split(',');
      }
    } else {
      this.formData.position = this.modal.payload.position;
    }
  },
  beforeDestroy() {
    this.close();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async submit() {
      const saveData = cloneDeep(this.formData);
      if (saveData.type === 0) {
        if (saveData.goodAnswers.length) {
          saveData.goodAnswers = this.formatAnswers(saveData.goodAnswers);
        }
        saveData.badAnswers = this.formatAnswers(saveData.badAnswers);
      } else if (saveData.type === 1 || saveData.type === 2) {
        // if not multiple, remove badAnswer and answerNumbers info
        saveData.badAnswers = null;
        saveData.answerNumbers = null;
      }

      if (this.modal.payload.submit) {
        await this.modal.payload.submit(saveData);

        this.close();
      }
    },
    checkType() {
      if (Number(this.formData.type) > 3) {
        this.$nextTick(() => {
          this.formData.type = 3;
        });
      }

      if (Number(this.formData.type) < 0) {
        this.$nextTick(() => {
          this.formData.type = 0;
        });
      }
    },
    checkAnswerNumbers() {
      if (Number(this.formData.answerNumbers) > 16) {
        this.$nextTick(() => {
          this.formData.answerNumbers = 16;
        });
      }

      if (Number(this.formData.answerNumbers) < 0) {
        this.$nextTick(() => {
          this.formData.answerNumbers = 0;
        });
      }
    },
    setMaxPointNumbers() {
      this.formData.maxPoint = this.formData.goodAnswers.length;
    },
    formatAnswers(answers) {
      return answers.join();
    },
    changeAnswers() {
      if (this.formData.type === 0) {
        this.formData.goodAnswers = [];
        this.formData.badAnswers = [];
      }
    },
    changeType() {
      if (this.formData.type === 0) {
        this.formData.goodAnswers = [];
        this.formData.badAnswers = [];
      } else {
        this.formData.goodAnswers = '';
        this.formData.badAnswers = '';
      }
    }
  }
};
</script>

<style lang="scss">
.modals-container {
  border: 1px solid #ffffff;
}

.edit-question-modal {
  &--header {
    text-align: center;
  }

  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(56, 187, 0, 0.6) !important;
    border-color: rgb(56, 187, 0, 0.6) !important;
  }

  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background-color: white !important;
  }

  &--content {
    text-align: center;
    margin-top: 10px;
    height: 636px;
    border: 1px solid #e4e6e8;
    border-radius: 10px;

    &-label {
      font-size: 18px;
      margin-top: 66px;
      text-align: center;
    }

    &-input {
      &-form {
        width: 70%;

        .v-input__slot {
          padding-left: 0 !important;
        }
      }

      label {
        font-size: 12px;
        font-weight: 400;
      }

      input {
        font-weight: 700;
      }
    }
  }

  &--buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    button {
      padding: 10px 0 10px;
      height: 38px;
      width: 121px;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #e2e2ea;
      font-weight: 600;
      font-size: 14px;
      text-transform: none;
    }

    &-cancel {
      margin-right: 8px;
      color: #696974 !important;
    }
  }
}
</style>

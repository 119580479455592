<template>
  <div v-if="session" class="sessions-agents-header">
    <h1 class="font-weight-bold">{{ session.label }}</h1>
    <v-layout class="sessions-agents-header--list" row>
      <div v-if="isShowHeaderItems" class="sessions-agents-header--list-item">
        <span class="sessions-agents-header--list-item-title font-weight-bold">
          État de la session:
        </span>
        <span class="sessions-agents-header--list-item-value font-weight-regular">
          {{ setState }}
        </span>
      </div>

      <div class="sessions-agents-header--list-item">
        <span class="sessions-agents-header--list-item-title font-weight-bold">
          Date:
        </span>
        <span class="sessions-agents-header--list-item-value font-weight-regular">
          {{ formatTime(session.date) }}
        </span>
      </div>

      <div v-if="isShowHeaderItems" class="sessions-agents-header--list-item">
        <span class="sessions-agents-header--list-item-title font-weight-bold">
          Questionnaire:
        </span>
        <span class="sessions-agents-header--list-item-value font-weight-regular">
          {{ session.formName }}
        </span>
      </div>

      <div class="sessions-agents-header--list-item">
        <span class="sessions-agents-header--list-item-title font-weight-bold">
          Note de validation:
        </span>
        <span class="sessions-agents-header--list-item-value font-weight-regular">
          {{ `${session.minValid} / 20` }}
        </span>
      </div>

      <div class="sessions-agents-header--list-item">
        <span class="sessions-agents-header--list-item-title font-weight-bold">
          Note entretien:
        </span>
        <span class="sessions-agents-header--list-item-value font-weight-regular">
          {{ `${session.minInterview} / 20` }}
        </span>
      </div>

      <div class="sessions-agents-header--list-item">
        <span class="sessions-agents-header--list-item-title font-weight-bold">
          Validité:
        </span>
        <span class="sessions-agents-header--list-item-value font-weight-regular">
          {{ formatTime(session.accessAndValidity) }}
        </span>
      </div>
    </v-layout>

    <div v-if="isShowSubtitle" class="sessions-agents-header--sub-list d-flex justify-space-between align-center">
      <div class="sessions-agents-header--sub-list-status d-flex justify-start align-center">
        <div v-for="(item, index) in subHeaderData" :key="index" class="sessions-agents-header--sub-list-status-item d-flex justify-space-between align-center">
          <span class="sessions-agents-header--sub-list-status-item-title">
            {{ item.text }}
          </span>
          <span class="sessions-agents-header--sub-list-status-item-value">
            {{ item.value }}
          </span>
        </div>
      </div>

      <v-btn
        v-if="type === 'questions'"
        x-large
        :loading="isDisabled"
        :disabled="isDisabled || (questions.remainingQuestions === 0 && !isCanFinishSession)"
        @click="clickNextButton"
      >
        <div>
          {{ questions.remainingQuestions ? "Question suivante" : "Fin du questionnaire" }}
          <Next />
        </div>
      </v-btn>
      <!-- TODO : btn here -->
      <div v-else class="sessions-agents-header--sub-list d-flex justify-start align-center">
        <v-btn small class="sessions-agents-header--sub-list-returnPage align-center" @click="addAgents">
          Ajouter un stagiaire
        </v-btn>
        <v-btn x-large class="sessions-agents-header--sub-list-next align-center" @click="$emit('next-step')">
          Commencer le questionnaire <Next />
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

import { STATE_VALUE } from '@/common/constants';
import Next from '@/components/common/icons/Next.vue';

export default {
  name: 'SessionHeader',
  components: {
    Next
  },
  props: {
    questions: {
      type: Object,
      default: () => {}
    },
    isShowSubtitle: {
      type: Boolean,
      default: false
    },
    isShowHeaderItems: {
      type: Boolean,
      default: true
    },
    isCanFinishSession: {
      type: Boolean,
      default: true
    },
    isCurrentQuestionFullyAnswered: {
      type: Boolean,
      default: false
    },
    agents: {
      type: Array,
      default: () => []
    },
    allAgentsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isDisabled: false,
      sessionId: null
    };
  },
  computed: {
    ...mapGetters('sessions', ['session']),
    setState() {
      return STATE_VALUE[this.session.state];
    },
    agentsSubHeader() {
      return [
        {
          value: this.session.numberOfAgents,
          text: "Nb Stagiaires"
        },
        {
          value: this.session.numberOfPairedDevice,
          text: "Tablettes appairées"
        },
        {
          value: this.session.numberDeviceToPaire,
          text: "À appairer"
        }
      ];
    },
    questionsSubHeader() {
      return [
        {
          value: this.questions.questionsCount,
          text: "Nb questions"
        },
        {
          value: this.questions.currentQuestion,
          text: "Question actuelle"
        },
        {
          value: this.questions.remainingQuestions,
          text: "Questions restantes"
        }
      ];
    },
    subHeaderData() {
      if (this.session.state === 2) {
        return this.questionsSubHeader;
      }

      return this.agentsSubHeader;
    },
    type() {
      if (this.session.state === 2) {
        return 'questions';
      }

      return 'agents';
    }
  },
  watch: {
    agents: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (
          oldValue !== null &&
          newValue !== null &&
          oldValue !== undefined &&
          newValue !== undefined &&
          (newValue.some((value, index) => value !== oldValue[index]) || newValue.length !== oldValue.length)
        ) {
          this.fetchSessionById(this.sessionId);
        }
      }
    }
  },
  async mounted() {
    this.sessionId = this.$route.params.id;
  },
  methods: {
    ...mapActions('sessions', ['addAgentsToSession', 'fetchSessionById']),
    formatTime(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    disableNext5Seconds() {
      this.isDisabled = true;
      setTimeout(() => {
        this.isDisabled = false;
      }, 5000);
    },
    clickNextButton() {
      this.disableNext5Seconds();
      // si la question n'est pas totalement répondue et que l'utilisateur ne confirme pas
      // on bloque l'évènement
      if (
        !this.isCurrentQuestionFullyAnswered &&
        !window.confirm("Êtes-vous sûr de vouloir passer à la question suivante alors que tous les agents n'ont pas répondu ?")
      ) {
        return;
      }

      // Si pas d'erreur, on envoie l'évènement
      // Sessions.vue > saveFinalScore()
      return this.questions.remainingQuestions ? this.$emit('next-question') : this.$emit('finish-quiz');
    },

    addAgents() {
      const agentsIds = this.agents.map(item => item.agentId);
      this.$modals.open({
        name: 'AddAgentsModal',
        payload: {
          agents: this.allAgentsList.filter(
            item =>
              // on filtre les agents qui sont déjà dans la sessions
              !agentsIds.includes(item.id) &&
              // on filtre les agents qui ont un accès encore valable au moins un mois
              (item.accessEndDate === null || moment().add(1, 'month').isAfter(moment(item.accessEndDate)))
          ),
          submit: agentIds => {
            this.addAgentsToSession({ id: this.sessionId, agentIds });
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
.sessions-agents-header {
  h1 {
    font-size: 36px;
    line-height: 43px;
  }

  &--list {
    margin: 0 !important;
  }

  &--list-item {
    font-size: 16px;
    line-height: 26px;
    max-width: 320px;
    margin-right: 36px;

    &-title,
    &-value {
      display: inline-block;
      float: left;
    }

    &-value {
      margin-left: 8px;
    }
  }

  &--sub-list {
    margin-top: 50px;

    &-next {
      cursor: pointer;
    }

    &-status-item {
      max-width: 296px;

      &-value {
        padding-left: 30px;
      }
    }

    &-status-item,
    &-next-item {
      height: 70px;
      border: 1px solid #e4e6e8;
      border-radius: 4px;
      font-size: 20px;
      padding-left: 11px;
      padding-right: 11px;
    }

    &-status-item:nth-child(1),
    &-status-item:nth-child(2) {
      margin-right: 12px;
    }
    &-returnPage {
      margin-right: 1rem;
    }
  }
}
</style>

<template>
  <BasePopupModal :modal="modal" :overlay-color="'white'" :width="900">
    <div class="session-modal">
      <div class="session-modal--header font-weight-light">
        {{ $t(`modals.sessions.${modalType}.title`) }}
      </div>
      <div class="session-modal--content">
        <div class="session-modal--content-input d-flex justify-center">
          <v-form class="session-modal--content-input-form">
            <v-container>
              <v-text-field v-model="formData.label" :label="'Libellé'" :rules="labelRules" outline> </v-text-field>
              <v-text-field v-model="formData.date" :label="'Date de la session'" :rules="sessionDateRules" type="date" outline> </v-text-field>
              <v-select v-model="formData.formId" :items="forms" :label="'Le questionnaire à utiliser'" item-text="name" item-value="id" filled></v-select>
              <v-text-field
                v-model="formData.minValid"
                :label="'Note minimale pour valider la session'"
                type="number"
                step="0.1"
                :value="formData.minValid"
                outline
                :change="checkNumber('minValid')"
              ></v-text-field>
              <v-text-field
                v-model="formData.minInterview"
                :label="'Note minimale pour passer l\’entretien'"
                type="number"
                step="0.1"
                outline
                :change="checkNumber('minInterview')"
              >
              </v-text-field>
              <v-text-field
                v-model="formData.accessAndValidity"
                :label="'Date de fin de validité de l\’accès obtenu'"
                type="date"
                :rules="accessAndValidityDateRules"
                outline
              ></v-text-field>
            </v-container>
          </v-form>
        </div>
      </div>
      <div class="session-modal--buttons d-flex justify-end">
        <v-btn class="session-modal--buttons-cancel" outlined depressed @click="close">
          Annuler
        </v-btn>
        <v-btn class="session-modal--buttons-save" color="#38BB00" :disabled="isDisabled" dark depressed @click="submit">
          Valider
        </v-btn>
      </div>
    </div>
  </BasePopupModal>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { get, find } from 'lodash';
import axios from 'axios';

// import request from '@/services/helpers/request';

import BasePopupModal from '@/components/modals/base/BasePopupModal';

export default {
  name: 'SessionModal',
  components: {
    BasePopupModal
  },
  props: {
    modal: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: {
        label: '',
        accessAndValidity: new Date(2022, 6, 32).toISOString().slice(0, 10),
        date: new Date().toISOString().slice(0, 10),
        minInterview: 12,
        minValid: 15,
        state: 0,
        formId: null
      },
      type: 1,
      maxNumber: 20,
      minNumber: 0
    };
  },
  computed: {
    ...mapGetters('ui', ['config']),
    oldValues() {
      return this.modal.payload.oldValues;
    },
    forms() {
      return this.modal.payload.forms;
    },
    modalType() {
      const modal = ['edit', 'new'];
      return modal[this.type];
    },
    labelRules() {
      return [
        v =>
          !!v.match(/^[a-z0-9 `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/i) || "Caractères invalides",
        v => v.length > 0 || "Trop court",
        v => v.length < 51 || "Trop long"
      ];
    },
    sessionDateRules() {
      return [
        // Date before access validity end date
        v =>
          this.formData.accessAndValidity === null
            ? true
            : moment(v).format('YYYYMMDD') < moment(this.formData.accessAndValidity).format('YYYYMMDD') || "La date de la session doit être antérieur à la date de fin d'accès"
      ];
    },
    accessAndValidityDateRules() {
      return [v => new Date(v) > new Date(this.formData.date) || "La validité de l'accès ne peut pas être antérieure à la date de la session"];
    },
    isDisabled() {
      const disabled =
        !!this.formData.label.match(/^[a-z0-9 `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/i) &&
        this.formData.label.length > 0 &&
        this.formData.label.length < 51 &&
        moment(this.formData.date).format('YYYYMMDD') < moment(this.formData.accessAndValidity).format('YYYYMMDD') &&
        this.formData.accessAndValidity !== null &&
        this.formData.formId;
      return !disabled;
    }
  },
  async mounted() {
    if (this.oldValues) {
      this.formData = this.oldValues;
      this.type = 0;
      this.formData.date = moment(this.formData.date).format('YYYY-MM-DD');
      this.formData.accessAndValidity = moment(this.formData.accessAndValidity).format('YYYY-MM-DD');
      this.formData.formId = get(find(this.forms, { name: this.oldValues.formId }), 'id', null);
    } else {
      const ACCESS_INVALIDITY = await this.getAccessInvalidity();
      this.formData.accessAndValidity = moment(ACCESS_INVALIDITY) ? moment(ACCESS_INVALIDITY).format('YYYY-MM-DD') : moment();
    }
  },
  beforeDestroy() {
    this.close();
  },
  methods: {
    async getAccessInvalidity() {
      const config = this.config;
      const { ACCESS_INVALIDITY } = config;

      if (typeof ACCESS_INVALIDITY !== 'undefined' && ACCESS_INVALIDITY !== '') {
        return ACCESS_INVALIDITY;
      } else {
        return false;
      }
    },
    close() {
      this.$emit('close');
    },
    async submit() {
      if (this.modal.payload.submit) {
        await this.modal.payload.submit(this.formData);

        this.close();
      }
    },
    checkNumber(value) {
      if (Number(this.formData[value]) > Number(this.maxNumber)) {
        this.$nextTick(() => {
          this.formData[value] = Number(this.maxNumber);
        });
      }

      if (Number(this.formData[value]) < Number(this.minNumber)) {
        this.$nextTick(() => {
          this.formData[value] = Number(this.minNumber);
        });
      }
    }
  }
};
</script>

<style lang="scss">
.modals-container {
  border: 1px solid #ffffff;
}

.session-modal {
  &--header {
    text-align: center;
  }

  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(56, 187, 0, 0.6) !important;
    border-color: rgb(56, 187, 0, 0.6) !important;
  }

  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background-color: white !important;
  }

  &--content {
    text-align: center;
    margin-top: 10px;
    height: 636px;
    border: 1px solid #e4e6e8;
    border-radius: 10px;

    &-label {
      font-size: 18px;
      margin-top: 66px;
      text-align: center;
    }

    &-input {
      margin-top: 69px;

      &-form {
        width: 70%;

        .v-input__slot {
          padding-left: 0 !important;
        }
      }

      label {
        font-size: 12px;
        font-weight: 400;
      }

      input {
        font-weight: 700;
      }
    }
  }

  &--buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    button {
      padding: 10px 0 10px;
      height: 38px;
      width: 121px;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #e2e2ea;
      font-weight: 600;
      font-size: 14px;
      text-transform: none;
    }

    &-cancel {
      margin-right: 8px;
      color: #696974 !important;
    }
  }
}
</style>

import axios from 'axios';
import moment from 'moment';

import * as types from './mutation-types';

export default {
  [types.TOGGLE_LOADER](state) {
    state.isShowLoader = !state.isShowLoader;
  },
  async [types.GET_CONFIG](state) {
    axios
      .get(`/config.json?ts=${moment().format('YYYYMMDDHHmmss')}`)
      .then(response => {
        state.config = response.data;
      })
      .catch(() => {
        state.config = {
          apiUrl: "",
          ACCESS_INVALIDITY: "2026-12-31",
          APP_TITLE: "GreenStep",
          ENABLE_LIMITED_ACCESS: false
        }
      });
  }
};

<template>
  <div>
    <Control @add="addSession" @remove="removeSessionById" @edit="editSessionById" />
    <v-data-table
      v-model="selected"
      fixed-header
      :headers="headers"
      :items="sessions"
      :items-per-page="20"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50, -1]
      }"
      height="600"
      :single-select="true"
      show-select
      class="session-table"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      multi-sort
    >
      <template v-slot:header.label="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="session-table--sort-icon" />
        </div>
      </template>

      <template v-slot:header.date="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="session-table--sort-icon" />
        </div>
      </template>

      <template v-slot:header.state="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="session-table--sort-icon" />
        </div>
      </template>

      <template v-slot:header.formName="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="session-table--sort-icon" />
        </div>
      </template>

      <template v-slot:header.minValid="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
        </div>
      </template>

      <template v-slot:header.minInterview="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
        </div>
      </template>

      <template v-slot:header.accessAndValidity="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
        </div>
      </template>

      <template v-slot:item.date="{ item }">
        {{ formatTime(item.date) }}
      </template>

      <template v-slot:item.state="{ item }">
        {{ setState(item.state) }}
      </template>

      <template v-slot:item.accessAndValidity="{ item }">
        {{ formatTime(item.accessAndValidity) }}
      </template>
      <template v-slot:item.minValid="{ item }">
        {{ item.minValid.toFixed(2) }}
      </template>
      <template v-slot:item.minInterview="{ item }">
        {{ item.minInterview.toFixed(2) }}
      </template>

      <template v-slot:item.details="{ item }">
        <a @click.stop.prevent="$emit('set-session', item)">
          Détail
        </a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapActions } from 'vuex';

import { formatValues } from '@/mixins/formatValues.mixin';
import Control from '@/components/sessions/Control';
import TwoArrowSort from '@/components/common/icons/TwoArrowSort';

export default {
  name: 'SessionTable',
  components: {
    Control,
    TwoArrowSort
  },
  mixins: [formatValues],
  props: {
    sessions: {
      type: Array,
      default: () => []
    },
    forms: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      singleSelect: true,
      selected: [],
      sortBy: ['date', 'createdAt'],
      sortDesc: [true, true] // desc, desc
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Libellé",
          divider: true,
          align: 'start',
          sortable: true,
          width: '17%',
          value: 'label'
        },
        {
          text: "Date session",
          divider: true,
          align: 'start',
          width: '8%',
          sortable: true,
          value: 'date'
        },
        {
          text: "État",
          divider: true,
          align: 'start',
          width: '12%',
          sortable: true,
          value: 'state'
        },
        {
          text: "Questionnaire",
          divider: true,
          align: 'start',
          width: '12%',
          sortable: true,
          value: 'formName'
        },
        {
          text: "Note minimale pour valider la session",
          divider: true,
          width: '9%',
          align: 'start',
          sortable: false,
          value: 'minValid'
        },
        {
          text: "Note minimale pour passer l’entretien",
          divider: true,
          width: '9%',
          align: 'start',
          sortable: false,
          value: 'minInterview'
        },
        {
          text: "Validité de l’accès",
          divider: true,
          width: '8%',
          align: 'start',
          sortable: false,
          value: 'accessAndValidity'
        },
        {
          text: "Participants",
          divider: true,
          width: '5%',
          align: 'start',
          sortable: false,
          value: 'nbParticipant'
        },
        {
          text: "Succès",
          divider: true,
          width: '5%',
          align: 'start',
          sortable: false,
          value: 'nbSuccess'
        },
        {
          text: "Entretiens",
          divider: true,
          width: '5%',
          align: 'start',
          sortable: false,
          value: 'nbInterview'
        },
        {
          text: "Refus",
          divider: true,
          width: '5%',
          align: 'start',
          sortable: false,
          value: 'nbFail'
        },
        {
          text: '',
          divider: true,
          width: '5%',
          sortable: false,
          value: 'details'
        }
      ];
    },
    selectedSession() {
      return this.selected[0] || {};
    },
    selectedId() {
      return get(this.selectedSession, 'id', null);
    },
    selectedState() {
      return get(this.selectedSession, 'state', null);
    }
  },
  methods: {
    ...mapActions('sessions', ['removeSession', 'editSession', 'newSession']),
    removeSessionById() {
      if (!this.selectedId) {
        return;
      }

      this.$modals.open({
        name: 'RemoveSessionModal',
        payload: {
          state: this.selectedState,
          submit: () => {
            this.removeSession(this.selectedId);
          }
        }
      });
    },
    editSessionById() {
      if (!this.selectedId) {
        return;
      }

      this.$modals.open({
        name: 'SessionModal',
        payload: {
          oldValues: {
            label: this.selectedSession.label,
            date: this.selectedSession.date,
            formId: this.selectedSession.formName,
            accessAndValidity: this.selectedSession.accessAndValidity,
            state: this.selectedSession.state,
            minValid: this.selectedSession.minValid,
            minInterview: this.selectedSession.minInterview
          },
          forms: this.forms,
          submit: data => {
            this.editSession({ id: this.selectedId, data });
            this.selected = [];
          }
        }
      });
    },
    addSession() {
      this.$modals.open({
        name: 'SessionModal',
        payload: {
          oldValues: null,
          forms: this.forms,
          submit: data => {
            this.newSession(data);
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
.sessions-control-icon {
  padding: 0 4px;
}

.session-table {
  .v-data-table-header__icon {
    display: none;
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }

  &--sort-icon {
    min-width: 24px;
    min-height: 24px;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  td:nth-child(1) {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }

  tr:last-child {
    td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
  }

  thead {
    th {
      background-color: white;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }

    th:nth-child(1) {
      border-left: thin solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
